var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"home container align-items-center"},[(!_vm.loading)?_c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col-md-5 col-sm-12"},[_c('a',{ref:"home",attrs:{"href":"https://people.nisto.io/"}},[_c('img',{attrs:{"src":_vm.logoURL,"alt":"Nisto People","width":"100%"}})])])]):_vm._e(),_c('br'),_vm._m(0),_c('br'),_vm._m(1),(_vm.loading)?_c('div',{staticClass:"row justify-content-md-center"},[_vm._m(2)]):_vm._e(),(_vm.failed)?_c('div',{staticClass:"row justify-content-md-center pt-3"},[_vm._m(3)]):_vm._e(),(_vm.schema)?_c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col-md-8 col-sm-12"},[(_vm.schema)?_c('form',{staticClass:"survey-items",attrs:{"id":"survey-form"}},_vm._l((_vm.schema.properties.diversityData.properties),function(field,key){return _c('div',{key:key,staticClass:"form-group",class:{
            'no-title': field.title == null,
            hide: !(!field._hide && _vm.requiredFieldsPresent(field)),
          }},[(!field._hide && _vm.requiredFieldsPresent(field))?_c(field._uiElement || field.type + 'Field',{tag:"component",attrs:{"field":field,"field-name":key},model:{value:(_vm.result[key]),callback:function ($$v) {_vm.$set(_vm.result, key, $$v)},expression:"result[key]"}}):_vm._e()],1)}),0):_vm._e()])]):_vm._e(),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center"},[_c('button',{staticClass:"btn btn-teal",attrs:{"disabled":_vm.savingData},on:{"click":_vm.getId}},[_vm._v(" submit ")])])]),_c('br'),_c('br')])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('ul',{staticClass:"steps"},[_c('li',{staticClass:"complete"},[_vm._v("1")]),_c('li',{staticClass:"active teal"},[_vm._v("2")]),_c('li',{},[_vm._v("3")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col-md-8 col-sm-12"},[_vm._v(" Share your anonymous diverse identity by providing details about your background, experiences, and communities. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"lds-ellipsis"},[_c('div'),_c('div'),_c('div'),_c('div')])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-md-8 col-sm-12"},[_c('h2',{staticClass:"text-center",staticStyle:{"color":"red"}},[_vm._v(" We're currently experiencing problems. Please check back later. ")])])
}]

export { render, staticRenderFns }