
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class CheckboxField extends Vue {
  @Prop() field?: any;
  @Prop() fieldName?: string;
  private selectedItems: any = Vue.observable({});
  private textFieldItems: any = Vue.observable({});

  private ListContains(item: string) {
    return Object.keys(this.selectedItems).indexOf(item) > -1;
  }

  private showTextBox(fieldKey: string) {
    return (
      this.field._uiOptions &&
      this.field._uiOptions[fieldKey] &&
      this.field._uiOptions[fieldKey].showTextbox
    );
  }

  private triggerUpdate() {
    (this as any)._watchers
      .find((w: any) => w.expression === "selectedItems")
      .cb(this.selectedItems);
  }
  private getFieldValue() {
    return Object.keys(this.selectedItems)
      .filter((x) => this.selectedItems[x])
      .map((x) => this.textFieldItems[x] || x);
  }

  @Watch("textFieldItems", { deep: true })
  onTextFieldItemsChanged() {
    this.$emit("input", this.getFieldValue());
  }

  @Watch("selectedItems", { deep: true })
  onSelectedItemsChanged() {
    this.$emit("input", this.getFieldValue());
  }
}
