
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import vSelect from "vue-select";
@Component({
  components: { vSelect },
})
export default class SelectField extends Vue {
  @Prop() field?: any;
  @Prop() fieldName?: string;
  private selectedValue = "";

  @Watch("selectedValue")
  onSelectedValueChange(value: string, oldValue: string) {
    this.$emit("input", value);
  }
}
